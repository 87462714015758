// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let imgsProjeto = [
  {
    projeto: 'vf',
    imgHeader: '/assets/logo.svg',//'https://acesso.naufinance.com/nau/Content/Images/tgnome.png',
    imgLogin: '/assets/logo.svg'//'https://acesso.naufinance.com/nau/Content/Images/tgnome.png'
  },
  {
    projeto: 'Virtual Friend',
    imgHeader: '/assets/logo.svg',//'https://acesso.naufinance.com/nau/Content/Images/logo-small@1x_virtualfriend.png',
    imgLogin: '/assets/logo.svg'//'https://acesso.naufinance.com/nau/Content/Images/logo-small@1x_virtualfriend.png'
  }
];

export const environment = {
  production: false,
  urlAPI: 'https://acesso.gepbusiness.com/GEPJSON/ServerGepJson.dll/datasnap/rest/TSMJsonGep/',
  projeto: imgsProjeto[1].projeto, //'A2MIA',
  imgHeader: imgsProjeto[1].imgHeader, //'https://acesso.naufinance.com/nau/Content/Images/tgnome.png',
  imgLogin: imgsProjeto[1].imgLogin,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
